import { element } from 'prop-types';
import React from 'react';
import { useEffect, useState } from 'react';
import { classNames }  from "../utils/classNames"

export const ScrollToTop = () => {

    const [isVisible, setIsVisible] = useState(false)

    const toggleVisibility = () => {
        let el = document.getElementById('scrollContainer');

        if(el.scrollTop >= 200) {
            setIsVisible(true);
           
        } else if (el.scrollTop <= 200 ) {
            setIsVisible(false);
            
        }
    };

    const scrollToTop = () => {
        
        let el = document.getElementById('scrollContainer');
    
        el.scrollTo({
            top:0,
            behavior: 'smooth',
        });

        console.log('scrolledtotop');
    };

    useEffect(() => {
        let el = document.getElementById('scrollContainer');
        el.addEventListener('scroll', toggleVisibility);
        
        return () => {
            el.removeEventListener('scroll', toggleVisibility);
           
        };
       

    }, []);

    //Need to look at adding classes for when its mounted and unmounted - for visibility

    return (
        <div className={classNames(
            isVisible ? 'opacity-100' : 'opacity-0',
            'backtotop fixed-bottom transition'
        )}>
            <button type="button" onClick={scrollToTop}>
            <span></span> Back to Top
            </button>

        </div>    

    )

};