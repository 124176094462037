import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { TransitionLink, TransitionState } from 'gatsby-plugin-transition-link'
import Link from 'gatsby-plugin-transition-link'

import HeaderAnimation from '../components/HeaderAnimation'
import Menu from './Menu'
import ToggleIcon from '../assets/svg/toggle.inline.svg'

import $ from 'jquery'


const Header = ({ pageContext, toggleBackdrop, ...props }) => {

  //Should put logic for top of page here - to change the logo and the menu to be more consistent with top of page design

  const { wp } = useStaticQuery(graphql`
    {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)
  


  return (


    <div className="header-wrap">
    <header id="site-header" className="header-footer-group" role="banner">
      
      <div className="header-inner-wrap d-flex justify-content-between align-items-center l-m">
        <div className="logo-wrap">

        <HeaderAnimation />
         
        </div>

        <div className="nav-wrap">       
          <button className="toggle nav-toggle mobile-nav-toggle m-t nav-closed"
            data-toggle-target=".menu-modal"
            data-toggle-body-class="showing-menu-modal"
            aria-expanded="false"
            tabIndex="1"
            data-set-focus=".close-nav-toggle"
            onClick={(e) => toggleBackdrop(e, true)}>
            <div className="m-b">
              <div className="t-b-b"></div>
            </div>
            <span></span>
          </button>
        </div>
      </div>


      
    </header>
    <div id="top"></div>
    </div>
  )
}

export default Header
