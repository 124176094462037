import React from 'react'
import Menu from './Menu'
import Darkmode from './Darkmode'
import AnimationDisable from './AnimationDisable'
import {ScrollToTop} from './ScrollToTop';


export default class Footer extends React.Component {

render() {

  return (

  <footer>
    <div className="container-fluid">
        <div className="row">

            <div className="col-12">
                <div className="logo">

                <svg id="footerkologo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 611.14 109.01">
                    <g id="logo">
                        <path className="cls-1" d="M147.8,42c2-3.69-1.78-7.42-5.31-8.42-3.93-1.11-7.38,1.64-9.5,4.69-4,5.75-5.89,13.48-7.29,20.24-.54,2.62-.21,9.06,3.41,4.31s6.49-10,11.09-14.07c2.39-2.12,5.71-3.69,7.47-6.52Z" transform="translate(-0.83 0.01)"/>
                        <path className="cls-1" d="M232.62,13.18c-3.83,0-7.17,2.3-9.9,4.77-1.37,1.24-2.65,2.59-3.91,3.95s-.16,2.42.51,4c.54,1.25,1,2.52,1.44,3.81.83,2.52,1.48,5.09,2.1,7.66.24,1,.18,3.28,1.45,3.32,1.72.05,3.52-1.54,4.91-2.42,4.86-3.05,11.35-6.89,12.7-13C243.12,19.88,238.22,13.18,232.62,13.18Z" transform="translate(-0.83 0.01)"/>
                        <path className="cls-1" d="M198.26,49.6a2,2,0,0,0,.23-1.6l-1.27-8.52c-.15-1-.42-5.84-2-5.8a1,1,0,0,0-.35.11c-2.83,1.38-6.15,2.13-8.42,4.43-3,3-3.32,8.3.32,10.92,2.92,2.11,6.67,1.89,10,1.15a3.45,3.45,0,0,0,1.09-.38A1.8,1.8,0,0,0,198.26,49.6Z" transform="translate(-0.83 0.01)"/>
                        <path className="cls-1" d="M203.2,4.78a14,14,0,0,0-4.6-4.1,5.67,5.67,0,0,0-5.9.3c-2.7,2.2-1.6,6.4-.4,9.6a171.17,171.17,0,0,1,8.6,34.7c1.7,11.8,2.9,24.4.2,36.1a16.4,16.4,0,0,1-3.4,7.6,7.49,7.49,0,0,1-7.5,2.6,7.92,7.92,0,0,1-5-5.9c-.7-2.6-.4-5.4-.3-8.1.4-7.1,0-14.4-2.6-20.9s-7.9-12.4-14.8-14.3c-8.2-2.3-16.9,1.4-23.4,6.9S133,62,127.72,68.58c-7.8,9.6-18.3,18.4-30.6,18.8a12.1,12.1,0,0,0-7.35,2.2c-2.09,1.36-4.16,2.73-6.3,4-1.92,1.13-4.68,2.14-6.09,3.92-.46.58,0,1,.49,1.38,3.66,2.45,8.66,3.82,13,4.4a41.08,41.08,0,0,0,17.38-1.57,45.6,45.6,0,0,0,6.39-2.52c11.9-5.8,21.1-16.1,28.9-26.8,2.5-3.5,5.1-7.2,8.7-9.5s8.8-2.9,12.1-.1c3.8,3.2,3.6,9.1,2.6,14-1.9,9.7,0,21.8,9.08,27.5,9.8,5.7,22.7,5.5,31.7-1.8,15.5-12.6,17.1-37.6,14.2-55.9A90.1,90.1,0,0,0,203.2,4.78Z" transform="translate(-0.83 0.01)"/>
                        <path className="cls-1" d="M144.63,77.65c-1-.3-2.19,1.06-3.2,2.21q-4.32,5-9,9.53c-2.71,2.64-6.66,4.58-5.19,8.82s6.17,6.8,10.59,6.54c8-.47,9.15-10.19,9.11-16.45,0-3,.1-6.66-1.16-9.41C145.42,78.14,145,77.77,144.63,77.65Z" transform="translate(-0.83 0.01)"/>
                        <path className="cls-1" d="M409.85,50c0-3.45.08-7.66-1.45-10.9-2-4.2-8.6-5.8-12-2.4s-2.5,9.2-2.6,13.6-.3,8.7-.8,13.1c-1.2,8.6-4.1,17.9-11.4,23.1-3.6,2.6-8.3,4.4-12.6,2.9a14.75,14.75,0,0,1-5.76-3.81,32.09,32.09,0,0,1-2.31-2.64c-.45-.57-1.46-2.55-2.1-2.78s-2.7,1.46-3.39,1.87a79.66,79.66,0,0,1-7.34,3.8c-1.41.64-4.12,1-3.68,3,.53,2.44,2.8,4.51,4.28,6.41,6.4,8.2,17,14,27.6,12.6,10.3-1.3,19.3-10.3,24.6-18.7,4.65-7.37,6.65-16.23,7.9-24.75A92.91,92.91,0,0,0,409.85,50Z" transform="translate(-0.83 0.01)"/>
                        <path className="cls-1" d="M418,71.72a7.79,7.79,0,0,1-6.22-2.15c-.34-.32-.88-1.17-1.43-1-.77.2-.73,1.78-.8,2.34a29.06,29.06,0,0,1-2.35,9.22c-.45.91-1.32,2.05-1,3.13a2.53,2.53,0,0,0,.67,1,13.42,13.42,0,0,0,4,2.54,15.76,15.76,0,0,0,11.78.59c4.08-1.43,7.27-5,9.37-8.64,1.38-2.44-.73-5-1.85-7.11a29.15,29.15,0,0,1-2.84-8.27c-.14-.75-.53-2.92-1.42-2.1-.65.61-.88,2.46-1.12,3.29-.66,2.26-1.42,4.56-3.44,6A7.27,7.27,0,0,1,418,71.72Z" transform="translate(-0.83 0.01)"/>
                        <path className="cls-1" d="M482.72,38a5.17,5.17,0,0,0-1.5-.71,6.33,6.33,0,0,0-6,1.85c-3.61,3.47-5.06,8.93-7.1,13.36l-1.5,3.25c-.88,1.9-1.76,3.79-2.69,5.66-2,4.09-4.16,8.14-6.23,12.21-.24.48-1.12,2.62-1.75,2.63s-1.73-2.1-2-2.57c-1.26-1.94-2.31-4-3.45-6a122.54,122.54,0,0,1-6.18-12.41c-1.8-4.22-3.4-8.54-5.61-12.56-1.48-2.69-3.79-5.44-6.87-5.48-2.64,0-4.94,2.09-5.86,4.56a15.26,15.26,0,0,0-.28,7.83c1.37,8.06,4.86,15.58,8.35,23,3.26,6.92,6.61,13.93,11.64,19.7,2.48,2.84,5.46,5.41,9.07,6.52a7.82,7.82,0,0,0,6.08-.26c1.89-1,3.5-3,4.9-4.67a86.52,86.52,0,0,0,18.47-35,62.54,62.54,0,0,0,1.72-11.13,16,16,0,0,0-.53-6.16A7.2,7.2,0,0,0,482.72,38Z" transform="translate(-0.83 0.01)"/>
                        <path className="cls-1" d="M579.64,67.27c-.22,0-.5.15-.89.52a22.13,22.13,0,0,0-3.19,4.74A49,49,0,0,1,568.74,81c-1.08,1.13-2.17,2.25-3.33,3.31-.91.84-2.27,2-2.09,3.38.5,3.86,1.17,8.51,3.2,11.9a7.5,7.5,0,0,0,6.6,3.8,9.69,9.69,0,0,0,8.4-6.6c.83-2.51.35-5,0-7.59-.83-5.94-2-11.78-1.37-17.82a10.71,10.71,0,0,0,.12-2.77C580.16,67.8,580,67.3,579.64,67.27Z" transform="translate(-0.83 0.01)"/>
                        <path className="cls-1" d="M579.13,38.75a1.24,1.24,0,0,0-.2-1c-2.71-5.1-9-3.37-11.42,1A41.53,41.53,0,0,0,564,48.39a52.27,52.27,0,0,0-1.79,8c-.12,1.21.65,1.4,1.4.48,2.68-3.28,4.88-6.84,7.33-10.28a26.81,26.81,0,0,1,6.31-6.27C577.84,39.88,578.9,39.46,579.13,38.75Z" transform="translate(-0.83 0.01)"/>
                        <path className="cls-1" d="M352.49,80.06c1.37-.76,2.72-1.59,4.07-2.47A53.52,53.52,0,0,0,372.4,62.28c4.52-6.8,6.82-15.2,7.22-23.5.7-13.2-4.2-40-23-36.6-8.9,1.6-15.6,8.4-19.5,16.2a56.81,56.81,0,0,0-5.83,24.08,74.65,74.65,0,0,0,.34,9,75.59,75.59,0,0,0,1.53,9.33c.36,1.59.74,3.18,1.12,4.77.23,1,.34,2.8,1.67,2.86a2,2,0,0,0,.94-.26A43.7,43.7,0,0,0,348.73,60a1.94,1.94,0,0,0,.44-.55,2.07,2.07,0,0,0,0-1.28c-1.92-8.76-2.81-18-.35-26.61a23.38,23.38,0,0,1,4.3-8.62c1.47-1.77,3.68-3.34,5.91-2.79a5.78,5.78,0,0,1,3.48,3.2,18.08,18.08,0,0,1,1.68,8.57A39.9,39.9,0,0,1,356.81,54a49.4,49.4,0,0,1-12.2,12.06c-4.89,3.43-10.08,5.89-16.19,4.69a15.25,15.25,0,0,1-4.12-1.37c-5.6-3-7.6-9.1-10.5-14.4a33,33,0,0,0-10.6-12.3A37.69,37.69,0,0,0,282,35.9a36.09,36.09,0,1,0,31.77,53.36,36.66,36.66,0,0,0,1.77-3.75c.44-1.1.43-1.1-.5-1.53a43.45,43.45,0,0,1-7.57-4.43,25.22,25.22,0,0,1-3.31-2.88c-1-1.08-1.7-2.48-2.77-3.5-1.7-1.63-2.07,2.91-2.26,3.81-1.61,7.79-9.31,13.3-17.13,13.3a18.7,18.7,0,1,1,0-37.4,20.74,20.74,0,0,1,11.9,4.2,16.61,16.61,0,0,1,4.83,6.63c2.49,5.37,5.45,10.19,10.28,13.73a39.75,39.75,0,0,0,14.65,6.45,42.51,42.51,0,0,0,17.15.54A38.24,38.24,0,0,0,352.49,80.06Z" transform="translate(-0.83 0.01)"/>
                        <path className="cls-1" d="M401.42,22.58a8.9,8.9,0,1,0-8.9-8.9A9.19,9.19,0,0,0,401.42,22.58Z" transform="translate(-0.83 0.01)"/>
                        <path className="cls-1" d="M541.8,73.08c6.3-5.1,12.3-12,11.2-20.7a23.52,23.52,0,0,0-3.08-7.7,22.53,22.53,0,0,0-6.7-6.7c-5.7-3.8-12.4-4.2-18.9-2.3a36.18,36.18,0,0,0-21.75,17.26,34,34,0,0,0-3.83,11.8,34.46,34.46,0,0,0-.23,6.34c.06,1.23,0,5.58,1.31,6.15a1.54,1.54,0,0,0,.74,0,25.68,25.68,0,0,0,7.26-2.18c1.16-.52,2.29-1.09,3.43-1.65,1-.48,2.29-.9,2.76-2,.56-1.27-.18-3-.12-4.31A13.63,13.63,0,0,1,515,62.5a24,24,0,0,1,5.77-7.82,19.53,19.53,0,0,1,8.1-5.2,7,7,0,0,1,3.8-.3,4.22,4.22,0,0,1,2.9,2.3c.7,1.8-.4,3.8-1.6,5.4-3,4-6.6,7-10.3,10.2-3.9,3.3-7.8,6.7-12.3,9.1s-13.2,5.5-18.3,2.6A13.47,13.47,0,0,1,487.9,73a28.08,28.08,0,0,1-1.49-3.64c-.44-1.25-1.37-5.69-2.53-2.11a49.37,49.37,0,0,1-5.78,12.85c-.51.76-1.55,1.74-1.19,2.73.58,1.67,2.82,3.37,4.1,4.53a29.59,29.59,0,0,0,14.66,7.3c5.83,1,11.71-.2,17.14-2.39l.72-.3c1.35-.58,2.68-1.21,4-1.91C526.1,85.48,534.2,79.18,541.8,73.08Z" transform="translate(-0.83 0.01)"/>
                        <path className="cls-1" d="M610.9,48.6a16.24,16.24,0,0,0-4.78-6A22.19,22.19,0,0,0,593,38c-9.8-.2-17.4,6.7-22.9,14.3C565,59,559.92,66.18,554,73c-3.88,4.47-8.25,9-13.53,11.75a16.37,16.37,0,0,1-11.71,1.43,1.93,1.93,0,0,0-.79-.06c-1.1.2-2,1.27-2.84,1.93-3.91,3-8.3,4.89-12.86,6.65-1.27.49,1.37,2,1.79,2.26.92.55,1.88,1,2.83,1.5a28.4,28.4,0,0,0,6.63,2.39,32.63,32.63,0,0,0,14,.05c8.4-1.8,15.7-7.4,21.7-13.4a161,161,0,0,0,15.4-17.6c3.9-5.4,7.3-12.4,14.8-13.8a8.75,8.75,0,0,1,7.9,2.4c2.3,2.3,5.1,4.4,8.6,3.6C611.9,60.7,613.2,53.5,610.9,48.6Z" transform="translate(-0.83 0.01)"/>
                        <path className="cls-1" d="M113.92,45c-3.35-4.79-8-8.94-13.89-10-5.46-.94-11.4.54-16.33,2.87a42.11,42.11,0,0,0-10.08,6.77A33.56,33.56,0,0,0,63.13,73.74a32.57,32.57,0,0,0,1.13,5.64,1.09,1.09,0,0,0,1.48.81c2-.51,3.6-1.89,5.38-2.92A49,49,0,0,0,75.9,74.2c1.72-1.27,3-2.09,2.64-4.34-.91-6.47,3.81-12.32,8.62-16a24,24,0,0,1,5-3c2.37-1,7.48-1.77,8.64,1.49.81,2.27-2.49,5.72-3.79,7.17-4.76,5.32-10.59,9.74-16.35,13.93l-1.41,1L88,87.94c2.37-1.71,9.65-6.24,12.67-8.61C112.92,69.75,123,58,113.92,45Z" transform="translate(-0.83 0.01)"/>
                    </g>
                    <g id="logo">
                        <path className="cls-1" d="M23.72,82.94c-.9-5.39-1.35-10.77,0-15.71,2.25-7.63,9-13,16.16-16.6a6.94,6.94,0,0,1,2.28-.56c1.71-.11,2.32,1.23,1.77,2.68-1.78,4.72-5.14,8.53-8.74,12-2.14,2-.81,5.18.39,7.35S38.4,77,41,77.48a3.58,3.58,0,0,0,3.08-1.24c8-7.59,15.28-18.19,15.51-29.57v-.51a11,11,0,0,0-4.14-8.53c-4.12-3.32-11-3.31-15.95-2.35a38.4,38.4,0,0,0-15.41,7.29,61.52,61.52,0,0,0-6.68,5.81C9.36,56.46,3.53,67.23,3.53,78.45a49.41,49.41,0,0,0,7.27,25.39c1.1,1.84,2.43,3.89,4.48,4.77,2.44,1.06,5.5-.29,7.76-1.34,2.79-1.3,5-3.26,5.09-6.55a20,20,0,0,0-1.59-6.83A66.42,66.42,0,0,1,23.72,82.94Z" transform="translate(-0.83 0.01)"/>
                        <path className="cls-1" d="M24.17,38.51A144.72,144.72,0,0,1,30.9,10.24a9.77,9.77,0,0,0,.39-5.76C30.57,2,28.37,1.26,26,1.26c-2.59,0-4.45,1.68-6,3.52a65.77,65.77,0,0,0-6.56,9.92,97,97,0,0,0-6.26,13A98.08,98.08,0,0,0,.83,59.6c0,2.95,0,5.89.25,8.84.06.64.31.81.51.19.14-.46.31-.9.4-1.25.29-1,.61-2,1-3a44.89,44.89,0,0,1,2.39-5.57A54.77,54.77,0,0,1,13.51,47a52.41,52.41,0,0,1,9.22-7.5C22.93,39.32,24.14,38.71,24.17,38.51Z" transform="translate(-0.83 0.01)"/>
                        <path className="cls-1" d="M84.75,72.66a8.15,8.15,0,0,0-1.1,0c-2.85.31-5,2.22-7.33,3.73A88.25,88.25,0,0,1,68.54,81c-5.19,2.66-11,4.59-16.89,3.83C44.59,84,37.37,80.4,33.86,74c-1.71-3.15-2.27-6.77-3.6-10.08-.45-1.11-1.08-1.25-1.75-.25a22,22,0,0,0-3.4,12.49,29.79,29.79,0,0,0,2.55,12.36c3.73,7.81,12,11.82,20.27,13.13l1.37.19c6.13.72,12.22-.74,17.85-3.09,3.73-1.55,7.35-3.36,10.92-5.25,3.18-1.69,6.4-3.32,9.46-5.22,2.47-1.54,4.91-3.86,5.33-6.87C93.51,76.8,89.08,72.82,84.75,72.66Z" transform="translate(-0.83 0.01)"/>
                    </g>
                </svg>


                </div>

                <div className="footer-nav">
                    <Menu />
                </div>

                <div className="footer-copyright">
                    <p>© {new Date().getFullYear()} kentoliver.ca</p>
                </div>
                <ScrollToTop />
            </div>

            <div className="col-12 p-0 mt-5 border-top">
                <div className="accesibility-buttons d-flex align-items-center justify-content-center">
                    <Darkmode />
                    <AnimationDisable />
                </div>
            </div>
        </div>
    </div>
  </footer>
  )
}
}