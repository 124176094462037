import React from 'react'
import lottie from 'lottie-web'
import $ from 'jquery'

class Darkmode extends React.Component {
    
  componentDidMount() {
    const eggAnim = document.getElementsByClassName("dmlottiewrap");

    function darkmodeLottie(eggAnim) {

        var dmWrap = document.getElementsByClassName("darkmode-toggle");

        var animation =  lottie.loadAnimation({
            container: eggAnim,
            renderer: 'svg',
            loop: true,
            autoplay: false,
            animationData: require('../assets/lottie/egg.json')
        });

    
        eggAnim.addEventListener("mouseenter", function () {
            animation.play();
        });
        eggAnim.addEventListener("mouseleave", function () {
            animation.goToAndStop(0,0);
            animation.pause();
    
        });
    }
    
    for (var i = 0; i < eggAnim.length; i++) {
        darkmodeLottie(eggAnim[i]);
    }

  }

  componentWillUnmount() {
   
  }

  

  render() {
 
    function darkmode(e) {
        $('html').toggleClass('darkmode');
        if ( $('html').hasClass('darkmode') ) {
            $('.darkmode-toggle .dmlottiewrap p span').text('Dark Mode');
        } else {
            $('.darkmode-toggle .dmlottiewrap p span').text('Light Mode');
        }
    }

    return (

        <button onClick={darkmode} className="darkmode-toggle d-flex align-items-center">
            <div className="dmlottiewrap d-flex align-items-center justifty-content-center flex-row-reverse">   
                <p><span>Light Mode</span> Enabled</p>
            </div>
        </button>
     
    );
  }
};

export default Darkmode;
