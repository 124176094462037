const React = require("react")
const $ = require("jquery")
const Layout = require("./src/components/layout")

const Rellax = require("rellax")

exports.onInitialClientRender = () => {
    console.log("ReactDOM.render has executed")
   //script change here
    
}

exports.onClientEntry = () => {
    window.ready = () => {
    }
}

// Logs when the client route changes
exports.onRouteUpdate = ({ location, prevLocation }) => {

    setTimeout(function(){ 
        setTimeout(function(){ 
            console.log("%c🎟", "color: #001BFF; font-family: Raleway, sans-serif; font-size: 74vw; font-weight: 800;");

            setTimeout(function(){ 
                console.log("%c🎈Congratulations!🎈", "color: #ee5a6e; font-family: Raleway, sans-serif; font-size: 8vw; font-weight: 900;");

                setTimeout(function(){ 
                    console.log("%c🖍You are sneaky!🖍 ", "color: #ee5a6e; font-family: Raleway, sans-serif; font-size: 8.7vw; font-weight: 100;");

                    setTimeout(function(){ 

                        console.log("%cHave some sushi! ", "color: #ee5a6e; font-family: Raleway, sans-serif; font-size: 9.5vw; font-weight: 900;");

                        setTimeout(function(){ 
                            console.log("%c🍣", "color: #001BFF; font-family: Raleway, sans-serif; font-size: 74vw; font-weight: 800;");
                       }, 1000);       
                        
                    }, 1000);

                }, 1000);
            }, 1000);

        }, 1000);  
            
        
    }, 1000);
    

    // Opera 8.0+
    var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';

    // Safari 3.0+ "[object HTMLElementConstructor]" 
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/false || !!document.documentMode;

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;

    // Chrome 1 - 79
    var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

    // Edge (based on chromium) detection
    var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);

    // Blink engine detection
    var isBlink = (isChrome || isOpera) && !!window.CSS;

    if ( isChrome == true ) {
        $('#GatsbyBody').addClass('browser_chrome');
    } else if ( isFirefox == true ) {
        $('#GatsbyBody').addClass('browser_firefox');
    } else if ( isSafari == true ) {
        $('#GatsbyBody').addClass('browser_safari');
    } else if ( isIE == true ) {
        $('#GatsbyBody').addClass('browser_ie');
    } else if ( isEdge == true ) {
        $('#GatsbyBody').addClass('browser_edge');
    } else if ( isOpera == true ) {
        $('#GatsbyBody').addClass('browser_opera');

       

    } else if ( isEdgeChromium == true ) {
        $('#GatsbyBody').addClass('browser_chromium');
    } else if ( isBlink == true ) {
        $('#GatsbyBody').addClass('browser_blink');
    }

    var homepage   = '/',
        aboutpage      = '/about',
        servicespage   = '/services',
        portfoliopage  = '/portfolio',
        blogpage       = '/blog',
        resumepage       = '/resume',
        contactpage    = '/contact';
   
    $('body > div').removeClass();

    setTimeout(function(){ 

        if(location.pathname === '/' ){  

            require("./src/assets/js/home.js");

            $('body > div').addClass('home-page');


            setTimeout(function(){ 

                document.fonts.ready.then(function () {
                    (function(Blotter) {
                
                    Blotter.LiquidDistortMaterial = function() {
                    Blotter.Material.apply(this, arguments);
                    };
                
                    Blotter.LiquidDistortMaterial.prototype = Object.create(Blotter.Material.prototype);
                
                    Blotter._extendWithGettersSetters(Blotter.LiquidDistortMaterial.prototype, (function () {
                
                    function _mainImageSrc () {
                        var mainImageSrc = [
                        Blotter.Assets.Shaders.Noise3D,
                
                        "void mainImage( out vec4 mainImage, in vec2 fragCoord )",
                        "{",
                        "    // Setup ========================================================================",
                
                        "    vec2 uv = fragCoord.xy / uResolution.xy;",
                        "    float z = uSeed + uGlobalTime * uSpeed;",
                
                        "    uv += snoise(vec3(uv, z)) * uVolatility;",
                
                        "    mainImage = textTexture(uv);",
                
                        "}"
                        ].join("\n");
                
                        return mainImageSrc;
                    }
                
                    return {
                
                        constructor : Blotter.LiquidDistortMaterial,
                
                        init : function () {
                        this.mainImage = _mainImageSrc();
                        this.uniforms = {
                            uSpeed : { type : "1f", value : 0.1},
                            uVolatility : { type : "1f", value : 0.01},
                            uSeed : { type : "1f", value : 0.1 }
                        };
                        }
                    };
                
                    })());
                
                    })(
                    this.Blotter
                    );
                
                    const body = document.body;
                    const docEl = document.documentElement;
                
                    const MathUtils = {
                    lineEq: (y2, y1, x2, x1, currentVal) => {
                    // y = mx + b
                    var m = (y2 - y1) / (x2 - x1),
                        b = y1 - m * x1;
                    return m * currentVal + b;
                    },
                    lerp: (a, b, n) => (1 - n) * a + n * b,
                    distance: (x1, x2, y1, y2) => {
                    var a = x1 - x2;
                    var b = y1 - y2;
                    return Math.hypot(a, b);
                    }
                    };
                
                    let winsize;
                    const calcWinsize = () =>
                    (winsize = { width: window.innerWidth, height: window.innerHeight });
                
                    calcWinsize();
                    window.addEventListener("resize", calcWinsize);
            

                      document.querySelectorAll('.home-slider-wrap .slick-slide').forEach(
                        (allslides) => {
              
                        
                          const elem = allslides.querySelector(".blotter");

                          const textEl = allslides.querySelector("h2");
                    
                        const createBlotterText = () => {
                        const text = new Blotter.Text(textEl.innerHTML, {
                        family: "ImportedRalewayBlackItalic, sans-serif",
                        weight: 800,
                        size: 120,
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 20,
                        paddingBottom: 20,
                        fill: "white"
                        });
                        
                        //elem.removeChild(textEl);
                    
                        const material = new Blotter.LiquidDistortMaterial();
                        material.uniforms.uSpeed.value = 0.5;
                        material.uniforms.uVolatility.value = 0.02;
                        material.uniforms.uSeed.value = 0.1;
                        const blotter = new Blotter(material, { texts: text });
                        const scope = blotter.forText(text);
              
                        scope.appendTo(elem);
                    
              
              
                      };
                     
                      createBlotterText()
                          
                    }
                        
                );
                      
                }.bind(this));
              
              }, 600);

        } else if(location.pathname.indexOf(aboutpage) != -1){ 
            $('body > div').addClass('about-page');
            
        } else if(location.pathname.indexOf(servicespage) != -1){

            $('body > div').addClass('services-page');
            
        } else if(location.pathname.indexOf(portfoliopage) != -1){  
            
            $('body > div').addClass('portfolio-page');
            $('.modal-menu .nav-item:nth-of-type(4)').addClass('current-menu-item');
            
        } else if(location.pathname.indexOf(blogpage) != -1){

            $('body > div').addClass('blog-page');

        } else if(location.pathname.indexOf(contactpage) != -1){

            $('body > div').addClass('contact-page');
            
        } else if(location.pathname.indexOf(resumepage) != -1){

            $('html').addClass('resume-page');

        } else {

            $('body > div').addClass('other-page');
            $('.modal-menu .nav-item:nth-of-type(5)').addClass('current-menu-item');
            
            if ( $('.single-banner').length ) {

                /* Blotter */
                setTimeout(function(){ 

                    document.fonts.ready.then(function () {
                        (function(Blotter) {
                    
                        Blotter.LiquidDistortMaterial = function() {
                        Blotter.Material.apply(this, arguments);
                        };
                    
                        Blotter.LiquidDistortMaterial.prototype = Object.create(Blotter.Material.prototype);
                    
                        Blotter._extendWithGettersSetters(Blotter.LiquidDistortMaterial.prototype, (function () {
                    
                        function _mainImageSrc () {
                            var mainImageSrc = [
                            Blotter.Assets.Shaders.Noise3D,
                    
                            "void mainImage( out vec4 mainImage, in vec2 fragCoord )",
                            "{",
                            "    // Setup ========================================================================",
                    
                            "    vec2 uv = fragCoord.xy / uResolution.xy;",
                            "    float z = uSeed + uGlobalTime * uSpeed;",
                    
                            "    uv += snoise(vec3(uv, z)) * uVolatility;",
                    
                            "    mainImage = textTexture(uv);",
                    
                            "}"
                            ].join("\n");
                    
                            return mainImageSrc;
                        }
                    
                        return {
                    
                            constructor : Blotter.LiquidDistortMaterial,
                    
                            init : function () {
                            this.mainImage = _mainImageSrc();
                            this.uniforms = {
                                uSpeed : { type : "1f", value : 0.1},
                                uVolatility : { type : "1f", value : 0.01},
                                uSeed : { type : "1f", value : 0.1 }
                            };
                            }
                        };
                    
                        })());
                    
                        })(
                        this.Blotter
                        );
                    
                        const body = document.body;
                        const docEl = document.documentElement;
                    
                        const MathUtils = {
                        lineEq: (y2, y1, x2, x1, currentVal) => {
                        // y = mx + b
                        var m = (y2 - y1) / (x2 - x1),
                            b = y1 - m * x1;
                        return m * currentVal + b;
                        },
                        lerp: (a, b, n) => (1 - n) * a + n * b,
                        distance: (x1, x2, y1, y2) => {
                        var a = x1 - x2;
                        var b = y1 - y2;
                        return Math.hypot(a, b);
                        }
                        };
                    
                        let winsize;
                        const calcWinsize = () =>
                        (winsize = { width: window.innerWidth, height: window.innerHeight });
                    
                        calcWinsize();
                        window.addEventListener("resize", calcWinsize);
                    
                        const getMousePos = ev => {
                        let posx = 0;
                        let posy = 0;
                        if (!ev) ev = window.event;
                        if (ev.pageX || ev.pageY) {
                        posx = ev.pageX;
                        posy = ev.pageY;
                        } else if (ev.clientX || ev.clientY) {
                        posx = ev.clientX + body.scrollLeft + docEl.scrollLeft;
                        posy = ev.clientY + body.scrollTop + docEl.scrollTop;
                        }
                        return { x: posx, y: posy };
                        };
                    
                        let mousePos = { x: winsize.width / 2, y: winsize.height / 2 };
                        window.addEventListener("mousemove", ev => (mousePos = getMousePos(ev)));
                    
                        const elem = document.querySelector(".blotter");
                        const textEl = elem.querySelector("h2");
                    
                        const createBlotterText = () => {
                        const text = new Blotter.Text(textEl.innerHTML, {
                        family: "proxima-nova, sans-serif",
                        weight: 900,
                        size: 72,
                        paddingLeft: 50,
                        paddingRight: 50,
                        paddingTop: 50,
                        paddingBottom: 50,
                        fill: "white"
                        });
                        elem.removeChild(textEl);
                    
                        const material = new Blotter.LiquidDistortMaterial();
                        material.uniforms.uSpeed.value = 0.2;
                        material.uniforms.uVolatility.value = 0;
                        material.uniforms.uSeed.value = 0.1;
                        const blotter = new Blotter(material, { texts: text });
                        const scope = blotter.forText(text);
                        scope.appendTo(elem);
                    
                        let lastMousePosition = { x: winsize.width / 2, y: winsize.height / 2 };
                        let volatility = 0;
                    
                        const render = () => {
                        const docScrolls = {
                            left: body.scrollLeft + docEl.scrollLeft,
                            top: body.scrollTop + docEl.scrollTop
                        };
                        const relmousepos = {
                            x: mousePos.x - docScrolls.left,
                            y: mousePos.y - docScrolls.top
                        };
                        const mouseDistance = MathUtils.distance(
                            lastMousePosition.x,
                            relmousepos.x,
                            lastMousePosition.y,
                            relmousepos.y
                        );
                    
                        volatility = MathUtils.lerp(
                            volatility,
                            Math.min(MathUtils.lineEq(0.9, 0, 100, 0, mouseDistance), 0.9),
                            0.05
                        );
                        material.uniforms.uVolatility.value = volatility;
                    
                        lastMousePosition = { x: relmousepos.x, y: relmousepos.y };
                        requestAnimationFrame(render);
                        };
                        requestAnimationFrame(render);
                        };
                    
                        createBlotterText()
                    
                    }.bind(this));

                }, 1000);

            }
            

        }

    }, 300);

    require("./src/assets/js/general.js");

    /* Motion Sickness Observer */
    //Give iD
    $('body').attr('id', 'body');
    //Declare
    var motionsicknessid = document.getElementById('body')
    //Observer
    var motionsicknessobserver = new MutationObserver(function (event) {
       
    })
    //Check Class & Attributes
    motionsicknessobserver.observe(motionsicknessid, {
      attributes: true, 
      attributeFilter: ['class'],
      childList: false, 
      characterData: false
    }


    )

    /* Home */
    if( $('.top-of-page-item').length ) {

        /* In View */
        $.fn.isInViewport = function() {
            var elementTop = $(this).offset().top - 50;
            var elementBottom = elementTop + $(this).outerHeight();
            
            var viewportTop = $(window).scrollTop();
            var viewportBottom = viewportTop + $(window).height();
            
            return elementBottom > viewportTop && elementTop < viewportBottom;
        };

        if ($('.top-of-page-item').isInViewport()) {
            $('body').addClass('top-in-view');
        } else {
            $('body').removeClass('top-in-view');
        }
        
        $(window).on('resize', function() {
        
            if ($('.top-of-page-item').isInViewport()) {
                $('body').addClass('top-in-view');
            } else {
                $('body').removeClass('top-in-view');
            }

            setTimeout(function(){ 
        
            if ($('.top-of-page-item').isInViewport()) {
                $('body').addClass('top-in-view');
            } else {
                $('body').removeClass('top-in-view');
            }

            }, 300);


        });

        $('.site-wrap').on('scroll', function() {
            if ($('.top-of-page-item').isInViewport()) {
                $('body').addClass('top-in-view');
            } else {
                $('body').removeClass('top-in-view');
            }

            setTimeout(function(){ 
        
            if ($('.top-of-page-item').isInViewport()) {
                $('body').addClass('top-in-view');
            } else {
                $('body').removeClass('top-in-view');
            }

            }, 300);
        });
    }

    /* Menu Line */

    $('.nav-item').each(function() {
        $(this).removeClass('current-menu-item');
        if($(this).find('a.current-menu-item').length !== 0) {
        $(this).addClass('current-menu-item');
        }
    });

    var rellax = new Rellax('.rellax', {
        wrapper: '.site-wrap'
    });

    if( $('.darkmode').length ) { 
        $('.darkmode-toggle .dmlottiewrap p span').text('Dark Mode');

    }
    if( $('.motionsickness').length ) { 
        $('.motionsickness-toggle .mslottiewrap p span').text('Disabled');

    }

   
}