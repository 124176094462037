import React from 'react'
import TransitionLink from 'gatsby-plugin-transition-link'
import Link from 'gatsby-plugin-transition-link'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Menu from './Menu'


import lottie from 'lottie-web'

import $ from 'jquery'

export default class Footer extends React.Component {

  componentDidMount() {
    const motionAnim = document.getElementsByClassName("motion-lottie-wrap");

    function motionLottie(motionAnim) {

        var dmWrap = document.getElementsByClassName("motion-lottie");

        var animation =  lottie.loadAnimation({
            container: motionAnim,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('../assets/lottie/car.json')
        });

    
        document.getElementById('motionsickness').addEventListener("focus", function () {
          setTimeout(function(){ animation.pause() }, 1810);
        });
        // motionAnim.addEventListener("mouseleave", function () {
        //     animation.goToAndStop(0,0);
        //     animation.pause();
    
        // });
    }
    
    for (var i = 0; i < motionAnim.length; i++) {
        motionLottie(motionAnim[i]);
    }
  }
  

    motionSicknessHandler = e => {
        if (e.keyCode === 13) {
            if( $('html').hasClass('motionsickness') ) {

            } else {
              $('html').addClass('motionsickness');
              $('.motionsickness-toggle .mslottiewrap p span').text('Reduced');
            }
            
        }
    };

    render() {

    // function darkmode(e) {
    //     $('html').toggleClass('darkmode');
    //     if ( $('html').hasClass('darkmode') ) {
    //         $('.darkmode-toggle .dmlottiewrap p span').text('Dark Mode');
    //     } else {
    //         $('.darkmode-toggle .dmlottiewrap p span').text('Light Mode');
    //     }
    // }    

  return (

    <button id="motionsickness" onKeyDown={this.motionSicknessHandler} className="tab-detection" tabIndex={1}
        onFocus={(e) => {
        
        }} aria-label="Hello, I am noticing that you are navigating my website by using the TAB key. Before skipping to main content you can reduce motion sickness by pressing the ENTER key now.">

        
        
        <h1><span>Hello,</span> I am noticing that you are navigating my website by using the <span>TAB</span> key.</h1>

        <div className="motion-lottie-wrap">
        <div className="motion-lottie"></div>
        </div>
    
        <p>Before skipping to main content you can reduce motion sickness by pressing the <span>ENTER</span> key now.</p>

    </button>
  )
}
}