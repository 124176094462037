import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import lottie from 'lottie-web'
class HeaderAnimation extends React.Component {
    
  componentDidMount() {
    const headerAnimation = document.getElementsByClassName("lottie-header-wrap");
    function loadHeadAnimation(headerAnimation) {
        
      var animation =  lottie.loadAnimation({
        container: headerAnimation,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: require('../assets/lottie/white.json')
      });
  
        headerAnimation.addEventListener("mouseenter", function () {
            
  
            animation.setDirection(1);
            animation.play();
  
            if (animation.currentTime > (animation.totalFrames - 1)) {
              animation.pause();
          }
        });
        headerAnimation.addEventListener("mouseleave", function () {
            // animation.goToAndStop(0,0);
            // animation.pause();
  
            animation.setDirection(-1);
            animation.play();
  
            if (animation.currentTime > (animation.totalFrames - 1)) {
              animation.pause();
          }
        });
  
  
        headerAnimation.addEventListener("enterFrame", function (animation) {
          if (animation.currentTime > (animation.totalFrames - 1)) {
              animation.pause();
          }
      });
      }
  
      for (var i = 0; i < headerAnimation.length; i++) {
        loadHeadAnimation(headerAnimation[i]);
      }
  
  }

  componentWillUnmount() {
   
  }

  render() {
 
    return (

        <AniLink 
            fade 
            to="/" 
            duration={0.4}
            className="ko"
            tabIndex="1"
            id="logo"
            >
              <div className="lottie-header-wrap"></div>

          </AniLink>
     
    );
  }
};

export default HeaderAnimation;
