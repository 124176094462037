import React from 'react'
import lottie from 'lottie-web'
import $ from 'jquery'

class Darkmode extends React.Component {
    
  componentDidMount() {
    const carAnim = document.getElementsByClassName("mslottiewrap");

        function motionsicknessLottie(carAnim) {

            var msWrap = document.getElementsByClassName("motionsickness-toggle");

            var animation =  lottie.loadAnimation({
                container: carAnim,
                renderer: 'svg',
                loop: true,
                autoplay: false,
                animationData: require('../assets/lottie/car.json')
            });

        
            carAnim.addEventListener("mouseenter", function () {
                animation.play();
            });
            carAnim.addEventListener("mouseleave", function () {
                animation.goToAndStop(0,0);
                animation.pause();
        
            });
        }
        
        for (var i = 0; i < carAnim.length; i++) {
            motionsicknessLottie(carAnim[i]);
        }

  }

  componentWillUnmount() {
   
  }

  

  render() {
 
    function motionSickness(e) {
        $('html').toggleClass('motionsickness');
        if ( $('html').hasClass('motionsickness') ) {
            $('.motionsickness-toggle .mslottiewrap p span').text('Disabled');

            document.querySelectorAll('video').forEach(vid => vid.pause());

        } else {
            $('.motionsickness-toggle .mslottiewrap p span').text('Enabled');
            document.querySelectorAll('video').forEach(vid => vid.play());
        }
    }

    return (

        <button onClick={motionSickness} className="motionsickness-toggle d-flex align-items-center">    
            <div className="mslottiewrap d-flex align-items-center justifty-content-center flex-row-reverse">   
             <p>Animations <span> Enabled</span></p>
            </div>
        </button>
     
    );
  }
};

export default Darkmode;
